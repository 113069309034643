import "./App.css";
import Sidebar from "./components/Sidebar";
import Nav from "./components/Nav";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Overview } from "./pages/Overview";
import { Reports } from "./pages/Reports";
import Support from "./pages/Support";
import Control from "./pages/Control";

function App() {
  return (
    <Router>
      <div className="site-container">
        <div className="sidebar-container">
          <Sidebar />
        </div>
        <div className="content-container">
          <Nav />
          <Switch>
            <Route path="/overview" exact component={Overview} />
            <Route path="/reports" exact component={Reports} />
            <Route path="/control" exact component={Control} />
            <Route path="/support" exact component={Support} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
