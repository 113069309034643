import React from "react";

const Support = () => {
  return (
    <div className="home">
      <h1>Υποστήριξη</h1>
    </div>
  );
};

export default Support;
