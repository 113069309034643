import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TreeList,
  Scrolling,
  Paging,
  Pager,
  SearchPanel,
  Column,
  Sorting,
} from "devextreme-react/tree-list";
import "devextreme/dist/css/dx.common.css"; // Common styles
import "devextreme/dist/css/dx.light.css"; // Theme
import API_BASE_URL from "../config";

const detailsURL = `${API_BASE_URL}/api/v1/incident/details`;
export const Overview = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(detailsURL)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="home">
      <div className="inside-container">
        <h1>Γενικά</h1>
        <TreeList
          dataSource={data}
          columnAutoWidth={true}
          wordWrapEnabled={true}
          showBorders={true}
          keyExpr="_id"
        >
          <Scrolling mode="standard" />
          <SearchPanel visible={true} />
          <Paging enabled={true} defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20]}
            showNavigationButtons={true}
          />
          <Sorting mode="multiple" />
          <Column
            dataField="date_of_execution"
            caption="Date of Execution"
            dataType="date"
          />
          <Column dataField="vaccine_id" caption="Vaccine ID" />
          <Column
            dataField="execution_status_id"
            caption="Execution Status ID"
          />
          <Column dataField="doctor_amka" caption="Doctor AMKA" />
          <Column dataField="doctor_first_name" caption="Doctor First Name" />
          <Column dataField="doctor_last_name" caption="Doctor Last Name" />
          <Column dataField="patient_amka" caption="Patient AMKA" />
          <Column dataField="patient_first_name" caption="Patient First Name" />
          <Column dataField="patient_last_name" caption="Patient Last Name" />
          <Column
            dataField="vaccination_reason_id"
            caption="Vaccination Reason ID"
          />
          <Column dataField="municipality_id" caption="Municipality ID" />
          <Column dataField="place" caption="Place" />
          <Column dataField="medicine_lot" caption="Medicine Lot" />
          <Column dataField="supplier_lot" caption="Supplier Lot" />
          <Column
            dataField="expiry_date"
            caption="Expiry Date"
            dataType="date"
          />
        </TreeList>
      </div>
    </div>
  );
};

export default Overview;
