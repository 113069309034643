import React from "react";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "Γενικά",
    path: "/overview",
    icon: <RiIcons.RiHome2Line />,
  },
  {
    title: "Αναφορές",
    path: "/reports",
    icon: <RiIcons.RiFileList2Line />,
  },
  {
    title: "Διαχείριση",
    path: "/control",
    icon: <IoIcons.IoMdSettings />,
  },
  {
    title: "Υποστήριξη",
    path: "/support",
    icon: <IoIcons.IoMdHelpCircle />,
  },
];
