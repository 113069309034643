import React, { useEffect, useState } from "react";

export const Control = () => {
  //ADD NEW

  return (
    <div className="overview">
      <h1>Διαχείριση</h1>
    </div>
  );
};

export default Control;
