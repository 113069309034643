/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import styled from "styled-components";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import logo from "../assets/img/logo.svg";

import { RiCloseLine } from "react-icons/ri";
const SidebarNav = styled.nav``;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(true); // Initialize sidebar as visible

  const handleToggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
    const sidebarNav = document.querySelector(".sidebar-nav");
    if (sidebarNav) {
      sidebarNav.classList.toggle("show");
    }
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#60c525" }}>
        <div className="site-container">
          {" "}
          <nav className="sidebar-nav">
            <SidebarWrap>
              <span className="sidebar-close" onClick={handleToggleSidebar}>
                <RiCloseLine />
              </span>
              <div className="logos">
                <a
                  className="brand pull-left"
                  style={{
                    float: "none",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2rem",
                    padding: "2rem",
                  }}
                  href="#"
                >
                  <img src={logo} alt="Logo" />
                </a>
              </div>
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </SidebarWrap>
          </nav>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
